<template>
  <div>
    <div class="titlemName">{{ this.record.mName }}</div>
    <div class="displayFlex">
      <div>材料编号：{{ this.record.mNumber }}</div>
      <div>
        材料品牌：{{
          this.record.tmaterialBrand ? this.record.tmaterialBrand.mbName : ""
        }}
      </div>
      <div>
        材料类别：{{
          this.record.tmaterialType ? this.record.tmaterialType.mtName : ""
        }}
      </div>
      <div>材料销售价：{{ this.record.mSalesPrice }}</div>
      <div>单位：{{ this.record.mUnit }}</div>
      <div>重量：{{ this.record.mweight }}</div>
      <div>颜色：{{ this.record.mcolor }}</div>
      <div>体积：{{ this.record.mvolume }}</div>
      <div>规格描述：{{ this.record.mDesc }}</div>
    </div>
    <div class="Title2">供应商信息</div>
    <div>
      <!-- {{ this.record.tmaterialSuppliers }} -->
      <a-table
        ref="table"
        size="small"
        rowKey="msMModel"
        :columns="columns"
        :data-source="tabledata"
        :showPagination="false"
        :scroll="{ x: 800 }"
        tableLayout="fixed"
      >
        <span slot="tsupplier" slot-scope="text">
          <div>{{ text ? text.sname : "" }}</div>
        </span>
      </a-table>
    </div>
    <div class="Title2">大数据参考报价</div>
    <div
      class="ReferenceQuotation"
      v-for="(item, index) in ReferenceQuotation"
      :key="index"
    >
      <div>
        <a-icon :type="item.iconType" class="taobaoIcon" />
      </div>
      <div class="Quotation_Info">
        <div class="Quotation_Info_display">
          <div>
            {{ item.platform }}
          </div>
          <div>
            {{ item.name }}
          </div>
          <div>{{ item.StoreName }}</div>
        </div>
        <div class="Quotation_Info_display">
          <div>单价： {{ item.unitPrice }}</div>
          <div>规格： {{ item.specifications }}</div>
          <div>颜色：{{ item.color }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STable } from "@/components";
const columns = [
  {
    title: "供应商",
    width: "120px",
    scopedSlots: { customRender: "tsupplier" },
    dataIndex: "tsupplier",
    align: "center",
  },

  {
    title: "供应商类型",
    width: "100px",
    // scopedSlots: { customRender: "tmaterialBrand" },
    // dataIndex: "tmaterialBrand",
    align: "center",
  },
  {
    title: "供应商材料编号",
    width: "100px",
    scopedSlots: { customRender: "msMModel" },
    dataIndex: "msMModel",
    align: "center",
  },
  {
    title: "供应价",
    width: "90px",
    scopedSlots: { customRender: "msCostPrice" },
    dataIndex: "msCostPrice",
    align: "center",
  },
  {
    title: "发货地",
    width: "80px",
    scopedSlots: { customRender: "msPlace" },
    dataIndex: "msPlace",
    align: "center",
  },
  {
    title: "准备时长(h)",
    width: "80px",
    scopedSlots: { customRender: "msPreTime" },
    dataIndex: "msPreTime",
    align: "center",
  },
  {
    title: "配送时长(h)",
    width: "80px",
    scopedSlots: { customRender: "msDeliTime" },
    dataIndex: "msDeliTime",
    align: "center",
  },
  {
    title: "质量反馈记录",
    // width: "80px",
    // scopedSlots: { customRender: "munit" },
    // dataIndex: "munit",
    align: "center",
  },
];
const tabledata = [];

export default {
  name: "SupplierInfo",
  components: { STable },
  props: {
    record: {
      type: Object,
    },
  },
  data() {
    return {
      tabledata,
      columns,
      ReferenceQuotation: [
        {
          iconType: "taobao-circle",
          platform: "淘宝",
          name: "马可波罗卫生间瓷砖300*300",
          StoreName: "马可波罗旗舰店",
          unitPrice: "￥129.00",
          specifications: "300*300",
          unit: "片",
          color: "亚光白",
        },
        {
          iconType: "taobao-circle",
          platform: "淘宝",
          name: "马可波罗卫生间瓷砖300*300",
          StoreName: "马可波罗旗舰店",
          unitPrice: "￥129.00",
          specifications: "300*300",
          unit: "片",
          color: "亚光白",
        },
      ],
      // 查询参数
      queryParam: {
        mBcIds: "18",
        pageNo: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.tabledata = this.record.tmaterialSuppliers;
    console.log(" this.tabledata", this.record.tmaterialSuppliers);
  },
  mounted() {},
  watch: {
    // "formInline.mBcId": {
    //   handler(newValue) {
    //     this.GetTmaterialBrand(newValue);
    //     this.GetTmaterialType(newValue);
    //     this.GetMaterialSupplier(newValue);
    //   },
    // },
  },
  methods: {
    // 获取供应商信息
    GetMaterialSupplier() {},

    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="less" scoped>
* {
  background-color: #fff;
}
.titlemName {
  font-size: 20px;
  font-weight: 800;
}
.displayFlex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  //   justify-content: space-between;
  margin: 10px 0;
  background-color: #fff;
  div {
    margin: 10px 0;
    margin-right: 50px;
  }
}
.Title2 {
  font-weight: 800;
  font-size: 16px;
  margin-top: 30px;
}
.ReferenceQuotation {
  width: 95%;
  //   margin: 0 10px;
  //   //   text-align: center;
  height: 150px;
  background-color: #f9f9f9;
  //   position: absolute;
  /* 水平垂直居中 */
  //   left: 0;
  //   right: 0;
  //   top: 10px;
  //   bottom: 0;
  margin: auto;
  margin-top: 30px;
  border: rgba(206, 202, 202, 0.596) 1px solid;
  border-radius: 20px;
  display: flex;
  align-items: center;
  .taobaoIcon {
    font-size: 60px;
    color: #ff6900;
    margin: 0 20px;
  }
  .Quotation_Info {
    div {
      margin: 10px 20px 10px 0;
    }
    .Quotation_Info_display {
      display: flex;
    }
  }
}
</style>